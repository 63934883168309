<template>
  <Layout>
    <b-tabs content-class="mt-3">
      <b-tab title="Overview" active>
        <div class="campaign-details">
          <div class="status-title" style="color: red">
            <span> Waiting for Designs </span>
          </div>
          <div class="card p-4">
            <div class="p-4" v-if="campaign">
              <div>
                Sticker Type

                <strong>{{ campaign.sticker_type }}</strong> <br />
                Company : {{ campaign.company.name }} <br />

                <div style="font-size: 12px">
                  Phone: +{{ campaign.company.user_id.country_code }}-{{
                  campaign.company.user_id.phone_number
                  }}
                  <br />
                  Email: {{ campaign.company.user_id.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Upload Design" v-if="campaign.status === 1">
        <div class="row">
          <div class="col-12 my-4">
            <FormulateInput type="file" name="file" style="max-width: 480px; margin: auto" :uploader="uploadSampleFile"
              label="Please upload the sample" />
          </div>
          <div class="col-12 my-4">
            <FormulateInput type="file" name="file" style="max-width: 480px; margin: auto" :uploader="uploadDesignFile"
              label="Upload the design file here" help="Please zip files and then upload" />
          </div>
          <div class="col-12 text-center">
            <b-button @click="designDone">
              <span v-if="submitingForApproval">Submiting...</span>
              <span v-else>Submit for Approval</span>
            </b-button>
          </div>
          <div class="mt-4 p-3" style="border: 1px dashed black; width: 100%">
            <h6>Uploaded Files</h6>

            <div style="display: flex">
              <div class="download-file m-2">
                <div v-if="campaign.sample_file">
                  <img style="width: 180px" :src="campaign.sample_file" alt="Sample file" />
                  <br />
                  <a class="download-btn" target="_blank" :href="campaign.sample_file">
                    Sample File</a>
                </div>
                <div v-else class="pt-2">No sample file Uploaded</div>
              </div>
              <div class="download-file m-2">
                <div v-if="campaign.sticker_file" class="mt-4">
                  <i class="fa fa-file" style="font-size: 24px"></i> <br />
                  <a class="download-btn" target="_blank" :href="campaign.sticker_file">
                    Sticker File</a>
                </div>
                <div v-else class="pt-2">No Sticker Uploaded</div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaignData } from "@/data/campaign";
import { campaigns } from "@/config/api/campaigns";

export default {
  components: { Layout },
  created() {
    if (!this.$route.params.id) {
      this.$router.go(-1);
    }
    this.getCampaign();
  },
  data() {
    return {
      campaignData: campaignData,
      campaign: null,
      file: null,
      drivers: [],
      driverIsLoading: false,
      unassignedVehicle: [],
      loadingAssignedDrivers: false,
      addingDriver: false,
      removingDriver: false,
      currentDeletingDriver: null,
      assignedDriversList: [],
      submitingForApproval: false,
      options: ["list", "of", "options"],
    };
  },

  computed: {},
  methods: {

    getCampaign() {
      const api = campaigns.getCampaignDetails;
      api.id = this.$route.params.id;
      this.generateAPI(api).then((res) => {
        this.campaign = res.data.campaign;
      });
    },

    designDone() {
      const api = campaigns.designDone;
      api.data = {
        campaign_id: this.$route.params.id,
      };
      this.submitingForApproval = true;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.submitingForApproval = false;
          window.location.reload()
        });
    },
    changeStatus(status) {
      this.$store.dispatch("campaign/changeCampaignState", status);
    },

    uploadSampleFile(file, progress) {
      const api = campaigns.uploadSample;
      this.fileUploader(file, progress, api);
    },
    uploadDesignFile(file, progress) {
      const api = campaigns.uploadDesign;
      this.fileUploader(file, progress, api);
    },

    async fileUploader(file, progress, api) {
      const form = new FormData();
      form.append("file", file);
      form.append("campaign_id", this.campaign._id);

      api.data = form;
      const result = await this.fileUploadAPI(api, progress)
        .then((res) => {
          this.$bvToast.toast("Design uploaded successfully!", {
            title: "Upload Design",
            variant: "success",
            solid: true,
          });
          if (res.data.campaign.sticker_file) {
            this.campaign.sticker_file = res.data.campaign.sticker_file;
          }
          if (res.data.campaign.sample_file) {
            this.campaign.sample_file = res.data.campaign.sample_file;
          }

          return res.data;
        })
        .catch(() => {
          this.$bvToast.toast("Design is not uploaded!", {
            title: "Upload Design",
            variant: "danger",
            solid: true,
          });
        });
      return result;
    },
  },
};
</script>

<style>
.verify-btn {
  border: 2px solid #868686ec;
  font-weight: 900;
}

.verify-btn:hover {
  background: #868686ec;
}

.trade-licence {
  background-color: #fafafa;
  padding: 2px 8px;
  border-radius: 8px;
}

.trade-licence:hover {
  background-color: #f1f1f1;
}

.campaign-details .title {
  text-align: center;
  padding-top: 18px;
  font-weight: 900;
  color: black;
  text-transform: uppercase;
}

.campaign-details .col-4 {
  font-weight: 900;
}

.plan-details .row {
  margin-bottom: 4px;
}

.campaign-details .status-title {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 12px;
}

.download-file {
  box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  -webkit-box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  -moz-box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  padding: 28px 28px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 900;
  display: block;
  text-align: center;
}

.download-file:hover {
  color: gray;
}

.download-btn {
  background-color: purple;
  color: #fff;
  margin: 5px 10px;
  padding: 5px 10px;
  display: block;
  border-radius: 7px;
}

.download-btn:hover {
  color: #fff;
}
</style>