// export const campaignData = {
//     0: 'Campaign Added',
//     1: 'Verified',
//     2: 'Design Uploaded',

//     3: 'Design Verified',
//     4: 'Printing Done',
//     5: 'Design Rejected',
//     '-1':"Expired"
// }

export const campaignData = {
    0: 'Campaign Added',
    1: 'Verified',
    2: 'Design Uploaded',

    3: 'Design Verified',
    4: 'Printing Done',
    5: 'Completely Assigned',
    '-1':"Expired",
    '-2': "Campaign Rejected",
    '-3': 'Design Rejected',
}